(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const menuTrigger = document.querySelectorAll('.menu-trigger');
  const navigation = document.querySelector('.main-navigation');

  function handleDOMContentLoaded() {
    menuTrigger.forEach((el) => el.addEventListener('click', handleMenu));
  }

  function handleMenu(e) {
    if (e.currentTarget.classList.contains('menu-trigger--opened')) {
      e.currentTarget.classList.remove('menu-trigger--opened');
      navigation.classList.remove('main-navigation--opened');
    } else {
      e.currentTarget.classList.add('menu-trigger--opened');
      navigation.classList.add('main-navigation--opened');
    }
  }
})();
